/*
 * this is a pinia store that manages authentication
 */

import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthStore = defineStore("Auth", () => {
  const email = ref<string>("");

  function setEmail(newEmail: string) {
    email.value = newEmail;
  }

  return {
    email,
    setEmail,
  };
});
