/**
 * plugins/quasar.ts
 */

// Import icon libraries
import "@quasar/extras/material-symbols-rounded/material-symbols-rounded.css";
import iconSet from "quasar/icon-set/material-symbols-rounded";

// Import Quasar css
import "quasar/src/css/index.sass";

// Composables
import { Quasar, Lang } from "quasar";

const locale: string = Lang.getLocale() ?? "en-US";

// Dynamically import the language pack based on the detected browser languag
async function loadLangPack(lang: string) {
  switch (lang) {
    case "it":
    case "it-IT":
      return import("quasar/lang/it");
    default:
      return import("quasar/lang/en-US");
  }
}

export default {
  install(app: any) {
    loadLangPack(locale).then((langPack) => {
      app.use(Quasar, {
        config: {
          iconSet: iconSet, // Quasar icon set
        },
        lang: langPack.default, // Set the dynamically loaded language pack
      });
    });
  },
};
