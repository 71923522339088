<template>
  <notifications
    width="30%"
    :speed="250"
    :max="3"
    :ignoreDuplicates="true"
    classes="notifier"
    position="top right"
  />
</template>

<script lang="ts" setup></script>

<style lang="scss">
.vue-notification-group .notifier {
  margin: 10px;
  margin-top: 10px;

  border-radius: 5px;
  font-size: 14px;

  padding: 20px 30px;

  color: #495061;
  background: #eaf4fe;

  border: 1px solid #d4e8fd;

  .notification-title {
    font-size: 15px;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #fdeddc;
    border-color: #d18637;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }
}
</style>
