/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import router from "../router";
import sentry from "./sentry";
import pinia from "../stores";
import quasar from "./quasar";
import i18n from "./i18n";
import amplify from "./amplify";
import ECharts from "./echarts";
import hotjar from "./hotjar";

// Types
import type { App } from "vue";

export function registerPlugins(app: App) {
  app
    .use(router)
    .use(sentry)
    .use(pinia)
    .use(quasar)
    .use(i18n)
    .use(amplify)
    .use(ECharts)
    .use(hotjar);
}
