/**
 * main.ts
 */

import App from "./App.vue";
import { createApp } from "vue";
import { registerPlugins } from "@/plugins";
import Notifications from "@kyvg/vue3-notification";
import "./css/app.sass";

const app = createApp(App);
registerPlugins(app);

app.use(Notifications);
app.mount("#app");
