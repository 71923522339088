import axios, { AxiosError, AxiosResponse } from "axios";
import { ErrorData, notifyError } from "./notification";

export const apiPost = async <D, T>(
  endpoint: string,
  data: D,
  headers?: any,
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.post(endpoint, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    notifyError(error as AxiosError<ErrorData>);
    throw new Error((error as AxiosError<ErrorData>).message);
  }
};

export const apiGet = async <T>(endpoint: string, headers?: any): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.get(endpoint, headers);
    return response.data;
  } catch (error) {
    notifyError(error as AxiosError<ErrorData>);
    throw new Error((error as AxiosError<ErrorData>).message);
  }
};

export const apiPut = async <D, T>(endpoint: string, data: D): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.put(endpoint, data);
    return response.data;
  } catch (error) {
    notifyError(error as AxiosError<ErrorData>);
    throw new Error((error as AxiosError<ErrorData>).message);
  }
};
