/*
 * this is a pinia store that manages statistics
 */

import { defineStore } from "pinia";
import { ref } from "vue";
import { apiGet } from "@/core/api/api";
import { UserResponse } from "@/core/api/types/user-api";

export const useUserStore = defineStore("user", () => {
  let userId = ref("");
  let companyId = ref("");
  let companyName = ref("");
  let role = ref("");
  let hasCompany = ref(false);

  async function fetch(newUserId: string) {
    userId.value = newUserId;

    try {
      const response = await apiGet<UserResponse>(`/users/user`);
      if (response.CompanyId !== null && response.CompanyId !== "") {
        companyId.value = response.CompanyId;
        role.value = response.Role;
        hasCompany.value = true;
      }
    } catch (error: any) {
      console.error("An error occurred:", error);
    }
  }

  return {
    userId,
    companyId,
    companyName,
    hasCompany,
    role,
    fetch,
  };
});
