// Composables
import { createRouter, createWebHistory } from "vue-router";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";

const routes = [
  {
    path: "/auth",
    component: () => import("@/layouts/FullPage.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
      },
      {
        path: "/logout",
        name: "Logout",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Logout" */ "@/views/Logout.vue"),
      },
      {
        path: "/subscription",
        name: "Subscription",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Subscription" */ "@/views/Subscription.vue"
          ),
      },
      {
        path: "/new-company",
        name: "New Company",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "NewCompany" */ "@/views/NewCompany.vue"),
      },
      {
        path: "/configuration",
        name: "Configuration",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Configuration" */ "@/views/Configuration.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/Main.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ "@/views/Dashboard.vue"),
      },
      {
        path: "/interactions",
        name: "Interactions",
        component: () =>
          import(
            /* webpackChunkName: "Interactions" */ "@/views/Interactions.vue"
          ),
      },
      {
        path: "/contacts",
        name: "contacts",
        component: () =>
          import(/* webpackChunkName: "Customers" */ "@/views/Contacts.vue"),
      },
      {
        path: "/members",
        name: "Members",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Members" */ "@/views/Members.vue"),
      },
      {
        path: "/aspects",
        name: "Aspects",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Aspects" */ "@/views/Aspects.vue"),
      },
      {
        path: "/reports",
        name: "Reports",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Reports" /*/ "@/views/Reports.vue"),
      },
      {
        path: "/integrations",
        name: "Integrations",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Integrations" */ "@/views/Integrations.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/auth/login"];
  const authRequired = !publicPages.includes(to.path);

  // fetch session
  const session = await fetchAuthSession();

  // redirect to login page if not logged in and trying to access a restricted page
  if (authRequired && session.tokens === undefined) {
    return "/auth/login";
  }

  // force refresh session if it is not available
  if (session.tokens !== undefined) {
    await fetchAuthSession({ forceRefresh: true });
  }

  if (session.tokens !== undefined) {
    const authStore = useAuthStore();
    authStore.setEmail(session.tokens?.idToken?.payload?.email as string);

    // inject axios default headers
    const token = session.tokens?.idToken?.toString();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;

    // check if user is member of a company
    const user = useUserStore();
    await user.fetch(authStore.email);

    // if user has no company, redirect to new company page
    if (!user.hasCompany && to.name !== "New Company" && to.name !== "Logout") {
      return "/new-company";
    }
    // cannot create company if already joined a company
    if (user.hasCompany && to.name === "New Company") {
      return "/";
    }

    // // check if user has active subscription
    // const subscription = useSubscriptionStore();
    // await subscription.fetch();
    // // if subscription is not active, redirect to subscription page
    // if (
    //   !subscription.active &&
    //   to.name !== "Subscription" &&
    //   to.name !== "Logout"
    // ) {
    //   return "/subscription";
    // }

    // TODO: subscription flow needs to be updated and refactored with companies
    // check if user has active subscription
    // const subscription = useSubscriptionStore();
    // await subscription.fetch();
    // if subscription is not active, redirect to subscription page
    // if (
    //   !subscription.active &&
    //   to.name !== "Subscription" &&
    //   to.name !== "Logout"
    // ) {
    //   return "/subscription";
    // }

    // check if user has received at least one message
    const number_message = 1;
    if (
      number_message < 1 &&
      to.name !== "Configuration" &&
      to.name !== "Logout"
    ) {
      return "/configuration";
    }
  }
});

export default router;
