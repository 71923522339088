// Plugins
import { App } from "vue";
import Hotjar from "@hotjar/browser";

export default {
  install: (app: App) => {
    // If not https return
    if (window.location.protocol !== "https:") {
      return;
    }

    const siteId = import.meta.env.VITE_HOTJAR_SITE_ID;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    // Dynamically inject Hotjar script tag
    const script = document.createElement("script");
    script.innerHTML = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${siteId},hjsv:${hotjarVersion}};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;

    //document.head.appendChild(script);
    // add the script at the end of the head
    document.head.insertAdjacentElement("beforeend", script);
  },
};
