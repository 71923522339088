/**
 * plugins/echarts.ts
 */
import { App } from 'vue';
import * as echarts from 'echarts';
import VueECharts from 'vue-echarts';

export default {
  install(app: App) {
    // Register the echarts instance globally
    app.config.globalProperties.$echarts = echarts;

    // Register vue-echarts component globally
    app.component('v-chart', VueECharts);
  },
};
