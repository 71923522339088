import { AxiosError } from "axios";
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/plugins/i18n";

export type ErrorData = {
  ErrorCode: string;
  Message: string;
  Details?: any;
};

export const notifyError = (error: AxiosError<ErrorData>): void => {
  let errorCode = error.response?.data.ErrorCode;
  if (errorCode === undefined) {
    errorCode = "GENERIC_ERROR";
  }
  notify({
    title: i18n.global.t(`errors.${errorCode}.title`),
    text: i18n.global.t(`errors.${errorCode}.text`),
    type: "warn",
  });
};
