/**
 * @fileoverview i18n plugin for Vue.js
 */

import * as VueI18n from "vue-i18n";

const browserLocale: string = navigator.language;

/*
 * All i18n resources specified in the vite plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from "@intlify/unplugin-vue-i18n/messages";

export default VueI18n.createI18n({
  locale: browserLocale,
  legacy: false,
  fallbackLocale: "en-US",
  globalInjection: true,
  messages: messages,
});
