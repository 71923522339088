/**
 * plugins/amplify.ts
 *
 * Framework documentation: https://ui.docs.amplify.aws/vue/getting-started/installation`
 */

// Composables
import { Amplify } from "aws-amplify";
import AmplifyVue from "@aws-amplify/ui-vue";

Amplify.configure({
  //aws_appsync_authenticationType: import.meta.env.VITE_AWS_APPSYNC_AUTHENTICATION_TYPE,
  //aws_appsync_graphqlEndpoint: import.meta.env.VITE_AWS_APPSYNC_URL,
  //aws_appsync_region: import.meta.env.VITE_AWS_REGION,
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_CLIENT_ID,
      identityPoolId: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
    },
  },
  Analytics: {
    Pinpoint: {
      appId: import.meta.env.VITE_AWS_PINPOINT_APP_ID,
      region: import.meta.env.VITE_AWS_REGION,
    },
  },
  /*API: {
    endpoints: [
      {
        endpoint: import.meta.env.VITE_API_BASE_URL,
        name: "APIGateway",
        region: import.meta.env.VITE_AWS_REGION,
      },
    ],
  },*/
});

export default AmplifyVue;
